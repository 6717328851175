import React from 'react'
import { Helmet } from 'react-helmet'
import "@fontsource/open-sans"
import "@fontsource/raleway"
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
//import './all.sass'
import './all-easy.sass'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata()
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <script id="mcjs">
          {`!function (c, h, i, m, p) {
            m = c.createElement(h)
            p = c.getElementsByTagName(h)[0]
            m.async = 1
            m.src = i
            p.parentNode.insertBefore(m, p)
          }(document, "script", "https://chimpstatic.com/mcjs-connected/js/users/d4add4def21b7c0c336dce2de/a046bf9706aaa1fd1c4e3fd52.js")
          `}
        </script>

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/og-image.jpg`}
        />
      </Helmet>
      <Navbar />
      <div>{children}</div>
      <Footer />
    </div>
  )
}

export default TemplateWrapper
